import Features from "components/common/Features";
import HeroLayout2 from "components/common/hero/HeroLayout2";
import IndustryStripeSmall from "components/common/IndustryStripeSmall";
import InfoSlider from "components/common/InfoSlider";
import TrustedPartnerSection from "components/common/TrustedPartnerSection";
import { CTA_BUTTON_COPY } from "components/data/landing-page-data";
import GlobalLayout from "components/page/GlobalLayout";
import CustomerStoryCarousel from "components/vs/updated/CustomerStoryCarousel";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

export default function CompetitionLandingPage() {
  const renderContent = data => {
    const pageData = {
      header: {
        title: "Unmatched Cloud-Based Physical Security",
        paragraph:
          "Rhombus is ranked as the #1 physical security solution on G2. With an easy-to-use platform, plug-and-play setup, and world-class support, it’s no surprise that customers love using Rhombus to protect their spaces.",
        formId: "fa7fb89b-ef66-4638-aa6e-e35b012fc6c6",
        image: data.headerImage,
        mobileImage: data.headerImageMobile,
        theme: "light",
      },
      featuredLogos: {
        theme: "dark",
        logos: [
          {
            img: data.logo1,
            width: "130px",
            alt: "Hurley",
          },
          {
            img: data.logo2,
            width: "177px",
            alt: "Aramark",
          },
          {
            img: data.logo3,
            width: "115px",
            alt: "Stryker",
          },
          {
            img: data.logo4,
            width: "186px ",
            alt: "Los Angeles Film School",
          },
          {
            img: data.logo5,
            width: "149px",
            alt: "Goodwill",
          },
          {
            img: data.logo6,
            width: "137px",
            alt: "Clark Construction",
          },
        ],
      },
      infoSlider: {
        title: "The Benefits of Rhombus",
        list: [
          {
            title:
              "Stay in the know with real-time alerts for faces, vehicles, and more.",
            tabTitle: "Proactive Alerts",

            description:
              "Save time, accelerate investigations, and receive real-time intelligent alerts with Rhombus AI Analytics. Through machine-learning, the system automatically learns what ‘normal’ looks like in your spaces and becomes smarter and more effective.",
            image: data.benefit3,
          },
          {
            tabTitle: "Easy Investigations",
            title:
              "Save time with multi-camera investigations and smart search.",
            description:
              "No more manual scrubbing. Camera and sensor data are layered together for fast investigations. Quickly search past footage, search for events, and securely share clips via email, text, or URL all from the Rhombus timeline.",
            image: data.benefit4,
          },
          {
            tabTitle: "Seamless Deployment",
            title: "Register plug-and-play devices in as little as 5 minutes.",
            description:
              "Complex NVR/DVRs are a thing of the past. Rhombus’ modern architecture ensures fast and easy deployment from anywhere in the world. With simplified hardware infrastructure, installation is seamless with a single cable connection via PoE, eliminating the need for manual updates.",
            image: data.benefit2,
          },
          {
            tabTitle: "Infinite Scalability ",
            title: "Enjoy an all-in-one solution that grows with your needs.",
            description:
              "With a broad suite of products that is constantly growing, Rhombus provides a true single-pane-of-glass experience. Remotely manage access control, security cameras, alarms, sensors, and integrations from a centralized platform to improve visibility and operations at scale.",
            image: data.benefit1,
          },
        ],
      },
      features: [
        {
          icon: data.icon1,
          iconAlt: "User-Friendly",
          title: "User-Friendly",
          p:
            "Rhombus combines consumer-level ease of use with enterprise-grade performance, no technical expertise required.",
        },
        {
          icon: data.icon2,
          iconAlt: "AI Analytics",
          title: "AI Analytics",
          p:
            "Rhombus AI uses machine learning to save time, accelerate investigations, and send real-time intelligent alerts.  ",
        },
        {
          icon: data.icon3,
          iconAlt: "Bandwidth & Latency",
          title: "Bandwidth & Latency",
          p:
            "Get the best performance with 50% less bandwidth utilization and 10x better latency compared to other cloud vendors.",
        },
        {
          icon: data.icon4,
          iconAlt: "Infinite Scalability",
          title: "Infinite Scalability",
          p:
            "Scale geographically and vertically within your organization with unlimited devices, locations, and users.",
        },
      ],
      customerStories: {
        title: "Industry Leaders Trust Rhombus as their Security Solution",
        cards: [
          {
            img: data.story1,
            logo: {
              img: data.cslogo1,
              width: "131px",
              alt: "University Area CDC",
            },
            quote:
              "“I feel like the Rhombus team genuinely cares about our organization and the problems we're trying to solve—they were never just trying to sell us cameras.”",
          },
          {
            img: data.story2,
            logo: {
              img: data.cslogo2,
              width: "143px",
              alt: "Blake's Lota Burger",
            },
            quote:
              "“Similar tech on the surface, but Rhombus had more features, the portal was more user-friendly, and it had everything we needed. That was the start of our wonderful partnership.”",
          },
          {
            img: data.story3,
            logo: {
              img: data.cslogo3,
              width: "217px",
              alt: "Highlands School District",
            },
            quote:
              "“My biggest priority was not the latest discount another company could give me, but that my Superintendent will say that I chose the right partner and security platform in five years. That’s why I chose Rhombus over Verkada.”",
          },
        ],
      },
    };

    return (
      <GlobalLayout color="var(--gradient-light)" landingPage>
        <Helmet>
          <title>Rhombus - A Solution You Can Trust</title>
          <meta
            name="description"
            content="See why clients choose Rhombus over the competition."
          />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <HeroLayout2
          data={pageData.header}
          theme={pageData.header.theme}
          formTitle={CTA_BUTTON_COPY}
          buttonText={CTA_BUTTON_COPY}
        />
        <IndustryStripeSmall
          logos={pageData.featuredLogos.logos}
          theme={pageData.featuredLogos.theme}
        />
        <TrustedPartnerSection />
        <InfoSlider
          data={pageData.infoSlider.list}
          title={pageData.infoSlider.title}
          color="var(--nuetral-100)"
          button
        />
        <Features
          data={pageData.features}
          title="What Sets Rhombus Apart"
          color="var(--white)"
        />
        <CustomerStoryCarousel
          data={pageData.customerStories.cards}
          title={pageData.customerStories.title}
        />
      </GlobalLayout>
    );
  };
  const query = graphql`
    query {
      headerImage: file(
        relativePath: { eq: "components/vs/updated/img/vs-hero-img-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      headerImageMobile: file(
        relativePath: { eq: "components/vs/updated/img/vs-hero-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      logo1: file(
        relativePath: { eq: "components/common/logos/hurley-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo2: file(
        relativePath: { eq: "components/common/logos/aramark-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo3: file(
        relativePath: { eq: "components/common/logos/stryker-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo4: file(
        relativePath: { eq: "components/common/logos/lafs-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo5: file(
        relativePath: { eq: "components/common/logos/goodwill-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo6: file(
        relativePath: {
          eq: "components/common/logos/clark-construction-white.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      benefit1: file(
        relativePath: { eq: "components/demo/img/infinite-scalability-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefit2: file(
        relativePath: { eq: "components/demo/img/seamless-deployment-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefit3: file(
        relativePath: { eq: "components/demo/img/proactive-alerts-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefit4: file(
        relativePath: { eq: "components/demo/img/easy-investigations-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon1: file(
        relativePath: { eq: "components/industries/img/icons/system.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon2: file(
        relativePath: { eq: "components/industries/img/icons/learning.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon3: file(
        relativePath: { eq: "components/industries/img/icons/efficient.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon4: file(
        relativePath: { eq: "components/industries/img/icons/location.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }

      cslogo1: file(
        relativePath: { eq: "components/common/logos/uacdc-white-logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      cslogo2: file(
        relativePath: { eq: "components/common/logos/blakes-lotaburger.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      cslogo3: file(
        relativePath: {
          eq: "components/common/logos/highlands-school-district.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      story1: file(
        relativePath: { eq: "components/vs/updated/img/story-1-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      story2: file(
        relativePath: { eq: "components/vs/updated/img/story-2-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      story3: file(
        relativePath: { eq: "components/vs/updated/img/story-3.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  `;
  return <StaticQuery query={query} render={renderContent} />;
}
